.splash-container {
  background-color: white;
  position: absolute;
  width: 100%;
  height: 100vh;
  z-index: 100;
  transition: 1s;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.image-container {
  transition: 1.5s;
}

.splash-show {
  opacity: 1;
}

.splash-hide {
  opacity: 0;
  z-index: -1;
}

.animation-line {
  stroke-dasharray: 3000;
  stroke-dashoffset: 0;
  animation: dash 31s linear normal;
}

@keyframes dash {
  from {
    stroke-dashoffset: 3000;
  }
  to {
    stroke-dashoffset: 0;
  }
}
