@import url("https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

.header-container {
  position: sticky;
  z-index: 2;
  top: 0;
  color: rgba(0, 0, 0, 0.7);
  flex-direction: column;
  font-family: Lato, sans-serif;
}

.main-container {
  position: relative;
  z-index: 1;
  color: rgba(0, 0, 0, 0.7);
  flex-direction: column;
  padding: 24px 32px;
  gap: 20px;
  font-family: "Lato", sans-serif;
}

.poppins-font {
  font-family: "Poppins", sans-serif;
}

.lato-font {
  font-family: Lato, sans-serif;
}

.text-title {
  font-size: 20px;
  line-height: 22px;
  font-weight: 700;
  letter-spacing: 0;
  text-align: center;
}

.text-title-card {
  margin-top: 0.75em;
  font-size: 18px;
  line-height: 20px;
  font-weight: 600;
  letter-spacing: 0;
  text-align: center;
}

.image-styling {
  width: 350;
  align-self: center;
}

.schedule-card {
  background: rgba(97, 170, 174, 0.25);
  border-radius: 10px;
  padding: 0.5em 1em;
  margin: 0.5em 0;
  width: 100%;
}

.text-schedules {
  font-size: 18px;
  line-height: 20px;
  font-weight: 500;
  letter-spacing: 0;
  margin-bottom: 0.75em;
}

.text-schedules-date {
  font-size: 16px;
  line-height: 18px;
  font-weight: 500;
  letter-spacing: 0;
  margin-top: 1.5em;
  text-align: center;
}

.room-detail-info {
  padding: 0.75em 0;
}

.room-detail-info-card {
  padding: 0.25em 0;
}

.book-room-button {
  font-size: 18px;
  font-weight: 400;
  color: #fff !important;
  border-radius: 4px;
  padding: 16px 16px;
  width: 100%;
}

.text-value {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0;
  margin-left: 1.25em;
}

.text-variable {
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0;
  font-weight: 600;
}

.room-detail-info {
  padding-bottom: 0.75em;
}

.text-value {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0;
  margin-left: 1.25em;
}
