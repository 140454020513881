@charset "utf-8";
// Import Component main Sass File

// Import a Google Font
@import url("https://fonts.googleapis.com/css?family=Nunito:400,700");
@import url("https://fonts.googleapis.com/css?family=Poppins:400,500,600");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

// Set your brand colors
$purple: #1a4d76;
$pink: #fa7c91;
$brown: #757763;
$beige-light: #d0d1cd;
$beige-lighter: #eff0eb;

$gomess-light: #f1f8fa;
$gomess-light-invert: findColorInvert($gomess-light);

$gomess-danger: #f5365c;
$gomess-danger-invert: findColorInvert($gomess-danger);

$gomess-warning: #fb6340;
$gomess-warning-invert: findColorInvert($gomess-warning);

$gomess-dark: #08232d;
$gomess-dark-invert: findColorInvert($gomess-dark);

//Primary colors
$gomess-blue: #30c1ff; //	Active components (button & icons), and Male profile card
$gomess-pink: #ff8ecb; //	Female profile card
$gomess-black: #08232e; //	Active text
$gomess-white: #ffffff; // Main Background

//Secondary colors
$gomess-lightpink: #ffe2f2; // Menu card
$gomess-babyblue: #eaf9ff; // Menu card
$gomess-grey: #b1b1b1; // Inactive components (text, icons, border field)
$gomess-positivestate: #1aaa0d;

//Tertiary colors
$gomess-yellowmustard: #ee930a; // Remainder text
$gomess-neonblue: #1660ff; // Male status highlight
$gomess-pinkrose: #ff1593; // Female status highlight

$custom-colors: (
  "gomess-light": (
    $gomess-light,
    $gomess-light-invert,
  ),
  "gomess-danger": (
    $gomess-danger,
    $gomess-danger-invert,
  ),
  "gomess-warning": (
    $gomess-warning,
    $gomess-warning-invert,
  ),
  "gomess-dark": (
    $gomess-dark,
    $gomess-dark-invert,
  ),
  "gomess-blue": #30c1ff,
  //	Active components (button & icons), and Male profile card
  "gomess-pink": #ff8ecb,
  //	Female profile card
  "gomess-black": #08232e,
  //	Active text
  "gomess-white": #ffffff,
  // Main Background
  "gomess-lightpink": #ffe2f2,
  // Menu card
  "gomess-babyblue": #eaf9ff,
  // Menu card
  "gomess-grey": #b1b1b1,
  // Inactive components (text, icons, border field)
  "gomess-yellowmustard": #ee930a,
  // Remainder text
  "gomess-neonblue": #1660ff,
  // Male status highlight
  "gomess-pinkrose": #ff1593,
  // Female status highlight
  "gomess-positivestate": #1aaa0d // Female status highlight,,
);

// Update Bulma's global variables
$family-sans-serif: "Lato", sans-serif;
$grey-dark: $gomess-black;
$grey-light: $beige-light;
$primary: $gomess-blue;
$hr-background-color: $gomess-white;
$widescreen-enabled: false;
// $fullhd-enabled: false;

// Update some of Bulma's component variables
$body-background-color: $gomess-white;
$control-border-width: 2px;
$input-border-color: $gomess-grey;
$navbar-breakpoint: 325px;

$spacing-shortcuts: (
  "margin": "mg",
);
$spacing-shortcuts: (
  "padding": "pd",
);
$spacing-horizontal: "h";
$spacing-vertical: "p";
$spacing-values: (
  "small": 10px,
  "medium": 50px,
  "large": 100px,
  "xlarge": 200px,
);

$modal-breakpoint: 330px;
$modal-content-margin-mobile: 10px;
$modal-content-spacing-mobile: 80px;
$card-radius: 1rem;
$card-shadow: 4px 4px 20px 4px #08232e 10%;
$box-shadow: 4px 4px 20px 4px #08232e 10%;
$box-radius: 24px;

$steps-vertical-min-height: 6em;
$steps-marker-size: 2.25;
$steps-divider-size: 0.3rem;
$steps-active-color: $gomess-blue;
$steps-completed-color: $gomess-blue;
@import "node_modules/bulma-o-steps/bulma-steps.sass";

// Import only what you need from Bulma
@import "node_modules/bulma/sass/utilities/_all.sass";
@import "node_modules/bulma/sass/helpers/_all.sass";
@import "node_modules/bulma/sass/base/_all.sass";
@import "node_modules/bulma/sass/form/_all.sass";
@import "node_modules/bulma/sass/elements/button.sass";
@import "node_modules/bulma/sass/elements/container.sass";
@import "node_modules/bulma/sass/elements/title.sass";
@import "node_modules/bulma/sass/elements/notification.sass";
@import "node_modules/bulma/sass/layout/hero.sass";
@import "node_modules/bulma/sass/layout/section.sass";
@import "node_modules/bulma/sass/components/navbar.sass";
@import "node_modules/bulma/sass/components/modal.sass";
@import "node_modules/bulma/sass/components/card.sass";
@import "node_modules/bulma/sass/components/panel.sass";
@import "node_modules/@creativebulma/bulma-collapsible/src/sass/index.sass";
@import "node_modules/@creativebulma/bulma-tooltip/src/sass/index.sass";
