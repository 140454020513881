@import url("https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

.header-container {
  position: sticky;
  z-index: 2;
  top: 0;
  color: rgba(0, 0, 0, 0.7);
  flex-direction: column;
  font-family: Lato, sans-serif;
}

.poppins-font {
  font-family: Poppins, sans-serif;
}

.lato-font {
  font-family: Lato, sans-serif;
}

.text-title-card {
  margin-top: 0.75em;
  font-size: 18px;
  line-height: 20px;
  font-weight: 600;
  letter-spacing: 0;
  text-align: center;
}

.room-detail-info {
  padding: 0.85em 0;
}

.text-value {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0;
  margin-left: 1.25em;
}

.text-variable {
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0;
  font-weight: 600;
}

.room-detail-info {
  padding-bottom: 0.75em;
}

.text-value {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0;
  margin-left: 1.25em;
}
