.header-container {
  position: sticky;
  z-index: 999;
  top: 0;
  color: rgba(0, 0, 0, 0.7);
  flex-direction: column;
  font-family: Lato, sans-serif;
}

.main-container {
  padding: 2em;
  flex-direction: column;
  font-family: Lato, sans-serif;
}

.subheader-container {
  margin-bottom: 1.5em;
  flex-direction: column;
  font-family: Lato, sans-serif;
}

@media screen and (max-width: 768px) {
  .main-container {
    padding: 2em 1.25em;
    flex-direction: column;
    font-family: Lato, sans-serif;
  }
}
