@import url("https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

.filter-container {
  width: 100%;
  padding: 18px 38px 6px 38px;
  background: #fff;
}

.text-labels {
  height: 100%;
  vertical-align: middle;
  width: 70px;
  font-weight: 500;
  font-size: 14px;
}

.input-styling-text {
  width: calc(100% - 200px);
  border-radius: 6px;
  font-size: 12px;
  border-width: 1px;
}

.input-styling-text-right {
  width: 100%;
  border-radius: 6px;
  font-size: 12px;
  border-width: 1px;
}

.input-styling-number {
  width: 60px;
  border-radius: 6px;
  font-size: 12px;
  border-width: 1px;
}
