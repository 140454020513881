@import url("https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

.contact-us-card {
  width: 100%;
  padding: 18px;
  gap: 18px;
  background: #fff;
  background: #d0e2e3;
  font-family: "Lato";
  box-shadow: 0px 15.7778px 39.4444px rgba(18, 17, 39, 0.08);
  border-radius: 10px;
  margin-bottom: 18px;
}

.contact-us-box-left {
}

.contact-us-box-right {
}

.contact-us-contact-type-icon {
}

.contact-us-contact-name {
  font-family: "Lato";
  font-size: 18px;
  font-weight: 600;
  color: #121127;
  line-height: 150%;
}

.contact-us-contact-number {
  font-family: "Lato";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  color: rgba(18, 17, 39, 0.71);
}
