.header-container {
  position: sticky;
  z-index: 1;
  top: 0;
  color: rgba(0, 0, 0, 0.7);
  flex-direction: column;
  font-family: Lato, sans-serif;
  margin-bottom: -0.5em;
}

.main-container {
  z-index: 2;
  flex-direction: column;
  font-family: Lato, sans-serif;
}
