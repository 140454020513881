@import url("https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

.header-container {
  position: sticky;
  top: 0;
  color: rgba(0, 0, 0, 0.7);
  flex-direction: column;
  font-family: "Lato", sans-serif;
}

.main-container {
  color: rgba(0, 0, 0, 0.7);
  flex-direction: column;
  padding: 20px 28px;
  margin-bottom: 6em;
  gap: 20px;
  font-family: "Lato", sans-serif;
}

.text-title {
  font-size: 20px;
  line-height: 22px;
  font-weight: 700;
  letter-spacing: 0;
  text-align: center;
}

.room-detail-data {
  margin-left: 0.75em;
}

.room-detail-image {
  width: 350;
  align-self: center;
}

.schedule-card {
  background: rgba(97, 170, 174, 0.25);
  border-radius: 10px;
  padding: 0.5em 1em;
  margin: 0.5em 0;
  width: 100%;
}

.text-schedules {
  font-size: 18px;
  line-height: 20px;
  font-weight: 500;
  letter-spacing: 0;
  margin-bottom: 0.75em;
}

.text-schedules-date {
  font-size: 16px;
  line-height: 18px;
  font-weight: 500;
  letter-spacing: 0;
  margin-top: 0.25em;
  text-align: center;
}

.input-date {
  margin-bottom: 1.5em;
}

.room-detail-info {
  padding-bottom: 0.75em;
}

.room-detail-info-card {
  padding: 0.25em 0;
}

.book-room-button {
  color: #fff !important;
  border-radius: 4px;
  width: 100%;
}

.text-status-title {
  font-size: 22px;
  line-height: 22px;
  font-weight: 700;
  letter-spacing: 0;
  text-align: center;
}

.text-status-subtitle {
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  text-align: center;
  margin-top: 0.25em;
}

.text-value {
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  margin-left: 1.25em;
}

.text-value-number {
  font-size: 16px;
  line-height: 18px;
  font-weight: 700;
  letter-spacing: 0;
  text-align: right;
}

.text-value-status {
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0;
  text-align: center;
  align-items: center;
  align-content: center;
  font-style: italic;
  color: #fff !important;
}

.text-value-schedule {
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0;
  margin-left: 1.25em;
}

.text-variable {
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  font-weight: 600;
}

.text-variable-schedule {
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0;
  font-weight: 600;
}

.no-agenda {
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0;
  font-weight: 700;
  text-align: center;
}

.schedule-numbering {
  padding: 0.25em 0;
}

.schedule-booking-status {
  padding: 0.2em 0;
  background: #707265;
  border-radius: 5px;
  text-align: center;
  letter-spacing: 0;
}

.schedule-booking-status-created {
  padding: 0.2em 0;
  background: #002f6b;
  border-radius: 5px;
  text-align: center;
  letter-spacing: 0;
}

.schedule-booking-status-cancelled {
  padding: 0.2em 0;
  background: #ee0000;
  border-radius: 5px;
  text-align: center;
  letter-spacing: 0;
}

.schedule-booking-status-completed {
  padding: 0.2em 0;
  background: #707265;
  border-radius: 5px;
  text-align: center;
  letter-spacing: 0;
}

.schedule-sub-section {
  padding: 0.25em 0;
}

.schedule-sub-section-cancel {
  padding: 0.5em 0;
}

.schedule-sub-section-status {
  padding: 0.25em 0;
  text-align: center;
}

.section-title {
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0;
  font-weight: 500;
  padding: 0.75em 0 0.25em 0;
}

.sub-section-container {
  padding: 0.75em 0 0.25em 0;
}

.sub-section-title {
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  font-weight: 700;
}

.text-room-name {
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 0;
}

.text-room-location {
  font-size: 14px;
  line-height: 12px;
  font-weight: 500;
  letter-spacing: 0;
  margin-bottom: 0.25em;
}

.text-room-capacity,
.text-room-type {
  font-size: 13px;
  line-height: 13px;
  font-weight: 500;
  letter-spacing: 0;
}

.text-room-variable {
  font-size: 13px;
  line-height: 16px;
  font-weight: 500;
  letter-spacing: 0;
}

.text-room-data {
  font-size: 13px;
  line-height: 14px;
  font-weight: 300;
  letter-spacing: 0;
}
