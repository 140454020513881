.container {
    display: flex;
    flex-direction: column;
    height: auto;
    min-height: 100vh;
    background-color: #E1E3E7;
}

.content {
    position: relative;
    display: flex;
    flex-direction: column;
    height: auto;
    flex: 1;
    flex-grow: 1;
    min-height: 100%;
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    background-color: #FFFFFF;
}