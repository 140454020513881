.header-container {
  position: sticky;
  z-index: 1;
  top: 0;
  color: rgba(0, 0, 0, 0.7);
  flex-direction: column;
  font-family: Lato, sans-serif;
}

.main-container {
  padding: 2em 1.25em;
  z-index: 2;
  flex-direction: column;
  font-family: Lato, sans-serif;
}
